import { get, post } from "../../req";

const CHAT_API_URL = process.env.NEXT_PUBLIC_CHAT_API_URL;

/**
 * Create token for current user for getStream Chat.
 * @returns Promise Promise {token: user token generated for current user, error: if have any error}
 */
export const createToken = async () => {
    return new Promise((resolve) => {
        get(`${CHAT_API_URL}-createUserToken`).then(res => {
            let token = res?.body?.data?.token
            resolve({ token, eroror: null });
        }).catch((e) => {
            resolve({ token: null, error: e })
        })
    });
}

/**
 *
 * @param {*} email of the user need to create user for.
 * @returns Promise {success: whether the http call success or  not, error: if have any error}
 */

export const createChatUser = async email => {
    return new Promise((resolve) => {
        post(`${CHAT_API_URL}-addUser`, { email }).then(res => {
            resolve({ success: true, error: null })
        }).catch(e => {
            resolve({ success: false, error: e })
        })
    });
}

/**
 *
 * @param {string} vendorId
 */
export function createGroupFor(vendorId) {
    return post(`${CHAT_API_URL}-createGroupForContractIntimation`, {
        vendorId,
        initiatedUserType: "pwa"
    })
}

/**
 *
 * @param {string} vendorId
 */
export function markChannelAsImportant(channelId, important) {
    return post(`${CHAT_API_URL}-markChannelAsImportantHandler`, { channelId, important })
}