import dynamic from "next/dynamic";

import { setSectionState } from "../../store/weddingWebsite/weddingWebsite";
// Group editor components
const editorComponents = {
  WbaddLivestreamLink: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/live-stream/WbaddLivestreamLink"
    )
  ),
  WbAddActivity: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddActivity"
    )
  ),
  WbAddGif: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddGif"
    )
  ),
  WbAddHotel: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddHotel"
    )
  ),
  WbAddPerson: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPerson"
    )
  ),
  WbAddPhoto: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPhoto"
    )
  ),
  WbAddPhotoMemory: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPhotoMemory"
    )
  ),
  WbAddStory: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddStory"
    )
  ),
  WbAddTitle: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddTitle"
    )
  ),
  WbAddTransport: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddTransport"
    )
  ),
  WbQnA: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbQnA"
    )
  ),
  WbTextBlock: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbTextBlock"
    )
  ),
};

// Group shared components
const sharedComponents = {
  ActivityView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/ActivityView"
    )
  ),
  GifView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/GifView"
    )
  ),
  HotelView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/HotelView"
    )
  ),
  LiveStreamView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/LiveStreamView"
    )
  ),
  MediaView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/PhotoGallery"
    )
  ),
  PartialImageCarousel: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/sliders/PartialImageCarousel"
    )
  ),
  FullImageCarousel: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/sliders/FullImageCarousel"
    )
  ),
  PersonView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/PersonView"
    )
  ),
  QandAView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/QandAView"
    )
  ),
  ExpandableQandAView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/ExpandableQandAView"
    )
  ),
  StoryView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryView"
    )
  ),
  StoryViewArya: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryViewArya"
    )
  ),
  StoryViewSaira: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryViewSaira"
    )
  ),
  StoryViewKalyani: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/kalyani/StoryViewKalyani"
    )
  ),
  StoryViewSandhya: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/sandhya/StoryView"
    )
  ),
  StoryViewJiya: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryViewJiya"
    )
  ),
  StoryViewEsha: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/esha/StoryView"
    )
  ),
  TextBlockView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TextBlockView"
    )
  ),
  TimeLineView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TimeLineView"
    )
  ),
  TitleView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TitleView"
    )
  ),
  TransportView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TransportView"
    )
  ),
};

export const TemplateSubtitle =
  "A collection of our favorite moments captured over the years. ";

export const WbSections = {
  Title: {
    id: "Title",
    validationVariables: ["titleText"],
    renderComponent: sharedComponents.TitleView,
    editor: editorComponents.WbAddTitle,
    handlers: (dispatch, index) => {
      return {
        onTitleTypeChange: titleType =>
          dispatch(setSectionState({ index, data: { titleType } })),
        onTitleTextChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
      };
    },
  },

  TextBlock: {
    id: "TextBlock",
    validationVariables: ["titleText", "bodyText"],
    renderComponent: sharedComponents.TextBlockView,
    editor: editorComponents.WbTextBlock,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
        onBodyChange: bodyText =>
          dispatch(setSectionState({ index, data: { bodyText } })),
      };
    },
  },

  Photo: {
    id: "Photo",
    validationVariables: ["image"],
    renderComponent: ({ templateId, ...props }) => {
      let PhotoGallery = sharedComponents.MediaView;
      if (
        [
          TemplateIds.ARYA,
          TemplateIds.SAIRA,
          TemplateIds.KALYANI,
          TemplateIds.ESHA,
        ].includes(templateId)
      ) {
        PhotoGallery = sharedComponents.PartialImageCarousel;
      } else if (TemplateIds.SANDHYA === templateId) {
        PhotoGallery = sharedComponents.FullImageCarousel;
      }
      return <PhotoGallery {...props} />;
    },
    editor: editorComponents.WbAddPhoto,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: images => {
          dispatch(setSectionState({ index, data: { images } }));
        },
        handleDeleteImageClick: images => {
          dispatch(setSectionState({ index, data: { images } }));
        },
      };
    },
  },

  GIf: {
    id: "GIf",
    validationVariables: ["gif"],
    renderComponent: sharedComponents.GifView,
    editor: editorComponents.WbAddGif,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: gif => {
          dispatch(setSectionState({ index, data: { gif } }));
        },
        handleDeleteGif: () => {
          dispatch(setSectionState({ index, data: { gif: null } }));
        },
      };
    },
  },

  PhotoTimeline: {
    id: "PhotoTimeline",
    validationVariables: ["date", "description", "image"],
    renderComponent: sharedComponents.TimeLineView,
    editor: editorComponents.WbAddPhotoMemory,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
        handleDeletePhotoMemory: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
      };
    },
  },

  LiveStream: {
    id: "LiveStream",
    validationVariables: ["title", "buttonText", "livestreamLink"],
    renderComponent: sharedComponents.LiveStreamView,
    editor: editorComponents.WbaddLivestreamLink,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onButtonTextChange: buttonText =>
          dispatch(setSectionState({ index, data: { buttonText } })),
        onLivestreamLinkChange: livestreamLink =>
          dispatch(setSectionState({ index, data: { livestreamLink } })),
      };
    },
  },

  Story: {
    id: "Story",
    validationVariables: ["title", "description"],
    renderComponent: ({ templateId, ...props }) => {
      let StoryComponent;
      switch (templateId) {
        case TemplateIds.ARYA:
          StoryComponent = sharedComponents.StoryViewArya;
          break;
        case TemplateIds.SAIRA:
          StoryComponent = sharedComponents.StoryViewSaira;
          break;
        case TemplateIds.KALYANI:
          StoryComponent = sharedComponents.StoryViewKalyani;
          break;
        case TemplateIds.SANDHYA:
          StoryComponent = sharedComponents.StoryViewSandhya;
          break;
        case TemplateIds.JIYA:
          StoryComponent = sharedComponents.StoryViewJiya;
          break;
        case TemplateIds.ESHA:
          StoryComponent = sharedComponents.StoryViewEsha;
          break;
        default:
          StoryComponent = sharedComponents.StoryView;
      }
      return <StoryComponent {...props} />;
    },
    editor: editorComponents.WbAddStory,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onSubtitleChange: subtitle =>
          dispatch(setSectionState({ index, data: { subtitle } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
        onSecondImageUpload: secondImage =>
          dispatch(setSectionState({ index, data: { secondImage } })),
        onSecondImageRemove: () =>
          dispatch(setSectionState({ index, data: { secondImage: null } })),
      };
    },
  },

  Person: {
    id: "Person",
    validationVariables: ["name", "role"],
    renderComponent: sharedComponents.PersonView,
    editor: editorComponents.WbAddPerson,
    handlers: (dispatch, index) => {
      return {
        handleDeletePerson: () => {
          dispatch(setSectionState({ index, data: { person: null } }));
        },
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onRoleChange: role =>
          dispatch(setSectionState({ index, data: { role } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        handleImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  QnA: {
    id: "QnA",
    validationVariables: ["question", "answer"],
    renderComponent: ({ templateId, ...props }) => {
      let QnAComponent;
      if (
        [
          TemplateIds.ARYA,
          TemplateIds.SAIRA,
          TemplateIds.KALYANI,
          TemplateIds.SANDHYA,
          TemplateIds.ESHA,
          TemplateIds.JIYA,
        ].includes(templateId)
      ) {
        QnAComponent = sharedComponents.ExpandableQandAView;
      } else {
        QnAComponent = sharedComponents.QandAView;
      }
      return <QnAComponent {...props} />;
    },
    editor: editorComponents.WbQnA,
    handlers: (dispatch, index) => {
      return {
        onQuestionChange: question =>
          dispatch(setSectionState({ index, data: { question } })),
        onAnswerChange: answer =>
          dispatch(setSectionState({ index, data: { answer } })),
      };
    },
  },
  Transport: {
    id: "Transport",
    validationVariables: [
      "title",
      "image",
      "email",
      "address",
      "phone",
      "website",
    ],
    renderComponent: sharedComponents.TransportView,
    editor: editorComponents.WbAddTransport,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Activity: {
    id: "Activity",
    validationVariables: [
      "name",
      "image",
      "email",
      "address",
      "phone",
      "website",
    ],
    renderComponent: sharedComponents.ActivityView,
    editor: editorComponents.WbAddActivity,
    handlers: (dispatch, index) => {
      return {
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Hotel: {
    id: "Hotel",
    validationVariables: [
      "name",
      "image",
      "phone",
      "address",
      "email",
      "website",
      "roomRate",
      "blockCode",
    ],
    renderComponent: sharedComponents.HotelView,
    editor: editorComponents.WbAddHotel,
    handlers: (dispatch, index) => {
      return {
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onBlockCodeChange: blockCode =>
          dispatch(setSectionState({ index, data: { blockCode } })),
        onCheckInDateChange: checkInDate =>
          dispatch(setSectionState({ index, data: { checkInDate } })),
        onCheckOutDateChange: checkOutDate =>
          dispatch(setSectionState({ index, data: { checkOutDate } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onRoomRateChange: roomRate =>
          dispatch(setSectionState({ index, data: { roomRate } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
      };
    },
  },
};

export const PageIds = {
  HOME: "home",
  OUR_STORY: "our-story",
  PHOTOS: "photos",
  WEDDING_PARTY: "wedding-party",
  RSVP: "rsvp",
  PRIVACY_URL: "privacy-url",
  QA: "qa",
  REGISTRY: "registry",
  THINGS_TO_DO: "things-to-do",
  TRAVEL: "travel",
  ADD_NEW_PAGE: "add-new-page",
  SECTION_SEPRATOR: "section-seperator",
};

export const TemplateIds = {
  ROSE_BUD: "rose-bud",
  TOLEDO: "toledo",
  ARYA: "arya",
  SAIRA: "saira",
  KALYANI: "kalyani",
  ESHA: "esha",
  JIYA: "jiya",
  SANDHYA: "sandhya",
  BROWN_RUST: "brown-rust",
  BLUE_LAGOON: "blue-lagoon",
  CERISE: "cerise",
};

export const PageIdToText = {
  [PageIds.HOME]: "Home",
  [PageIds.OUR_STORY]: "Our Story",
  [PageIds.PHOTOS]: "Photos",
  [PageIds.WEDDING_PARTY]: "Wedding Party",
  [PageIds.RSVP]: "RSVP",
  [PageIds.PRIVACY_URL]: "Privacy & URL",
  [PageIds.QA]: "Q + A",
  [PageIds.REGISTRY]: "Registry",
  [PageIds.THINGS_TO_DO]: "Things To Do",
  [PageIds.TRAVEL]: "Travel",
  [PageIds.SECTION_SEPRATOR]: "Featured Photo",
};

export const WebsiteEditorPages = {
  HOME: { text: "Home", id: PageIds.HOME },
  OUR_STORY: { text: "Our Story", id: PageIds.OUR_STORY },
  SECTION_SEPRATOR: { text: "Featured Photo", id: PageIds.SECTION_SEPRATOR },
  PHOTOS: { text: "Photos", id: PageIds.PHOTOS },
  WEDDING_PARTY: { text: "Wedding Party", id: PageIds.WEDDING_PARTY },
  RSVP: { text: "RSVP", id: PageIds.RSVP },
  PRIVACY_URL: { text: "Privacy & URL", id: PageIds.PRIVACY_URL },
  QA: { text: "Q + A", id: PageIds.QA },
  REGISTRY: { text: "Registry", id: PageIds.REGISTRY },
  THINGS_TO_DO: { text: "Things To Do", id: PageIds.THINGS_TO_DO },
  TRAVEL: { text: "Travel", id: PageIds.TRAVEL },
  ADD_NEW_PAGE: { text: "Add New Page", id: "add-new-page" },
  CUSTOME_SECTION: { text: "Custom Section", id: "custom-section" },
};

export function getWbPages(isSinglePageTemplate) {
  const Home = {
    id: PageIds.HOME,
    name: WebsiteEditorPages.HOME.text,
  };

  const OurStory = {
    id: PageIds.OUR_STORY,
    name: WebsiteEditorPages.OUR_STORY.text,
  };

  const SectionSeperator = {
    id: PageIds.SECTION_SEPRATOR,
    name: WebsiteEditorPages.SECTION_SEPRATOR.text,
  };

  const Photos = {
    id: PageIds.PHOTOS,
    name: WebsiteEditorPages.PHOTOS.text,
  };

  const WeddingParty = {
    id: PageIds.WEDDING_PARTY,
    name: WebsiteEditorPages.WEDDING_PARTY.text,
  };

  const QA = {
    id: PageIds.QA,
    name: WebsiteEditorPages.QA.text,
  };

  const Registry = {
    id: PageIds.REGISTRY,
    name: WebsiteEditorPages.REGISTRY.text,
  };

  const ThingsToDo = {
    id: PageIds.THINGS_TO_DO,
    name: WebsiteEditorPages.THINGS_TO_DO.text,
  };

  const Travel = {
    id: PageIds.TRAVEL,
    name: WebsiteEditorPages.TRAVEL.text,
  };

  const PrivacyUrl = {
    id: PageIds.PRIVACY_URL,
    name: WebsiteEditorPages.PRIVACY_URL.text,
  };

  if (isSinglePageTemplate) {
    const visiblePages = [PageIds.HOME, PageIds.OUR_STORY, PageIds.PRIVACY_URL];
    return [
      Home,
      OurStory,
      SectionSeperator,
      WeddingParty,
      QA,
      Travel,
      Photos,
      ThingsToDo,
      Registry,
      // RSVP,
      PrivacyUrl,
    ].map(p => ({ ...p, visible: visiblePages.includes(p.id) }));
  }

  return [
    Home,
    OurStory,
    Photos,
    WeddingParty,
    // RSVP,
    QA,
    Registry,
    ThingsToDo,
    Travel,
    PrivacyUrl,
  ].map(p => ({
    ...p,
    visible: [PageIds.HOME, PageIds.PRIVACY_URL].includes(p.id),
  }));
}

export const CreateWeddingWebsite = "Create Wedding Website";

// if we need to add more custom pages, we can add them here and also in next.config.js
export const CustomPageIds = [
  "custom1",
  "custom2",
  "custom3",
  "custom4",
  "custom5",
  "custom6",
  "custom7",
  "custom8",
  "custom9",
  "custom10",
];

export function getCustomPageId(pages) {
  const existingPageIds = pages.map(page => page.id);

  const availablePageIds = CustomPageIds.filter(
    id => !existingPageIds.includes(id)
  );
  if (!availablePageIds.length) {
    return null;
  }

  return availablePageIds[0];
}

export const WeddingPartyDummyData = Array(4).fill({
  name: "Priya",
  role: "made of honour",
  description: "<p class=text-center>Description</p>",
  image: "/images/wedding-websites/upload-photo.png",
});

export const PhotoGalleryDummayData = [
  { src: "/images/wedding-websites/rose-bud1.png", key: "dummy-img1" },
  { src: "/images/wedding-websites/rose-bud3.png", key: "dummy-img2" },
  { src: "/images/wedding-websites/rose-bud2.png", key: "dummy-img3" },
  { src: "/images/wedding-websites/rose-bud5.png", key: "dummy-img4" },
  { src: "/images/wedding-websites/rose-bud4.png", key: "dummy-img5" },
];

export const registries = [
  {
    id: "amazone",
    name: "Amazon",
    website: "https://www.amazon.com/wedding",
    show: true,
    logo: "/images/wedding-websites/registry/Amazon_logo.png",
  },
  {
    id: "crateandbarrel",
    name: "Crate And Barrel",
    website: "https://www.crateandbarrel.com/wedding-gift-registry/",
    show: true,
    logo: "/images/wedding-websites/registry/Cratebarrel_logo.png",
  },
  {
    id: "etsy",
    name: "Etsy",
    website: "https://www.etsy.com/in-en/registry",
    show: true,
    logo: "/images/wedding-websites/registry/Etsy_logo.png",
  },
  {
    id: "honeyfund",
    name: "Honeyfund",
    website: "https://shareasale.com/r.cfm?b=1046407&u=2305962&m=73485&urllink=&afftrack=",
    show: true,
    logo: "/images/wedding-websites/registry/Honeyfund_logo.svg",
  },
  {
    id: "cb2",
    name: "Cb2",
    website: "https://www.cb2.com/wedding-gift-registry/",
    show: true,
    logo: "/images/wedding-websites/registry/CB2_logo.png",
  },
  {
    id: "macys",
    name: "Macys",
    website: "https://www.macys.com/registry/wedding",
    show: true,
    logo: "/images/wedding-websites/registry/Macys_logo.png",
  },
  {
    id: "williams-sonoma",
    name: "Williams Sonoma",
    website: "https://www.williams-sonoma.com/registry/",
    show: true,
    logo: "/images/wedding-websites/registry/Williamssanoma_logo.svg",
  },
  {
    id: "potterybarn",
    name: "Potterybarn",
    website: "https://www.potterybarn.com/registry/?",
    show: true,
    logo: "/images/wedding-websites/registry/Potterybarn_logo.svg",
  },
  {
    id: "surlatable",
    name: "Surlatable",
    website: "https://www.surlatable.com/giftregistry",
    show: true,
    logo: "/images/wedding-websites/registry/Surlatable_logo.png",
  },
  {
    id: "target",
    name: "Target",
    website: "https://www.target.com/gift-registry/create-wedding-registry",
    show: true,
    logo: "/images/wedding-websites/registry/Target_logo.png",
  },
];

export function getReorderPageText(isSinglePageTemplate) {
  return isSinglePageTemplate ? "Manage Pages" : "Reorder Pages";
}

export const QnADummyData = [
  {
    question: "What is the dress code for the wedding?",
    answer:
      "We'd love for you to dress in semi-formal attire. Feel free to bring your style and flair to our special day!",
    key: "dummy-qna-1",
  },
  {
    question: "Can I bring a guest?",
    answer:
      "Please take a look at your invitation to see if you have a plus one. If you have any questions or need further clarification, feel free to reach out to us.",
    key: "dummy-qna-2",
  },
  {
    question: "Are children invited to the wedding?",
    answer:
      "While we adore your little ones, we've decided to have an adults-only wedding to ensure everyone can relax and enjoy the festivities.",
    key: "dummy-qna-3",
  },
  {
    question:
      "Will there be a live stream of the wedding for those who can't attend in person?",
    answer:
      "Yes, there will be a live stream of the wedding for those who can't attend in person.",
    key: "dummy-qna-4",
  },
];

export const JoinUsInCelebratingOurLove = "Join Us in Celebrating Our Love";

export const PreviewTemplateKey = "preview-template";

export  const defaultCoverImages = {
  ARYA: "arya-bannerimage.jpg",
  SAIRA: "saira-hero.jpg",
  KALYANI: "kalyani-hero.jpg",
  ESHA: "esha-hero.jpg",
  JIYA: "jiya-hero.jpg",
  SANDHYA: "sandhya-hero.jpg"
}
export  const defaultMobCoverImages = {
  ARYA: "arya-hero-mobile.jpg",
  SAIRA: "saira-hero-mobile.jpg",
  KALYANI: "kalyani-hero-mobile.jpg",
  ESHA: "esha-hero-mobile.jpg",
  JIYA: "jiya-hero-mobile.jpg",
  SANDHYA: "sandhya-hero-mobile.jpg"
}

export const HowWeMetTitle = "How We Met";

export const WeddingWebsiteDateFormat = "DD MMM YYYY";

export const ResellerPortalUrl = "https://www.secureserver.net/?pl_id=596408"