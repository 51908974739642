import { COLLECTIONS } from "../config/constants";
import { secureStorage } from "../lib/secureLocalStore";
import { getDocumentsByKeyAndValue } from "../models";


export async function chatAvailable() {
  const userData = localStorage.getItem("userData");
  const { MainEventId: mainEventId } = userData ? JSON.parse(userData) : {};

  if (!mainEventId) {
    console.warn("MainEventId is not available in userData.");
    return false;
  }

  const key = `${mainEventId}_initiate_chat`;
  const available = secureStorage.getItem(key);

  if (available) {
    return true;
  }

  try {
    const contracts = await getDocumentsByKeyAndValue(
      COLLECTIONS.Contracts,
      "mainEventId",
      mainEventId
    );

    const hasValidContract = contracts.some(
      contract =>
        contract.contractType === "dwContract" &&
        contract.contractState === "proposal"
    );
    secureStorage.setItem(key, hasValidContract);
    return hasValidContract;
  } catch (error) {
    console.error("Error fetching contracts:", error);
    return false;
  }
}
